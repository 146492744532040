<template>
    <div class="main">
        <el-form label-position="left">
            <el-form-item :label="this.lang['form.user']">
                <el-input v-model="form.username" />
            </el-form-item>

            <el-form-item :label="this.lang['form.pass']">
                <el-input v-model="form.password" />
            </el-form-item>

            <el-form-item :label="this.lang['form.name']">
                <el-input v-model="form.name" />
            </el-form-item>

            <el-form-item :label="this.lang['form.contact']">
                <el-input v-model="form.contact" />
            </el-form-item>

<!--            <el-form-item>-->
<!--                <el-tag type="info">-->
<!--                    提示:-->
<!--                </el-tag>-->
<!--            </el-form-item>-->

            <el-form-item>
                <el-button type="primary" @click="onSubmit" :disabled=isDisabled>{{lang['button.submit']}}</el-button>
                <el-button @click="clearForm">{{lang['button.reset']}}</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
export default {
    name: "agentAdd",
    data(){
        return {
            form:{
                username:'',
                password:'',
                name:'',
                contact:'',
            },
            isDisabled:false,//[创建下级代理按钮]是否禁止,
            lang:this.$lang.getLang('agent','addAgent'),
        }
    },
    methods:{
        //提交请求
        onSubmit(){
            var error = false;
            let obj = this.form;
            for (let objKey in obj) {
                let str = obj[objKey];
                if (str.length <= 1){
                    error = true;
                }
            }
            if (error){
                this.$alert(this.lang['errorMsg.form']);
                return;
            }
            this.isDisabled = true;
            this.$message(this.lang['msg.wait']);
            this.$api.post('Agent.Agent/addAgent',this.form).then((res)=>{
                this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    let code = this.$helper.getResErrorCode(res);
                    let title = 'ErrCode:' + code;
                    let field = 'res.code.' + code;
                    let errMsg;
                    if (this.lang[field] != null){
                        errMsg = this.lang[field];
                        if (code == 1 && res.data.msg != null){
                            errMsg = errMsg + '<br>Msg:' + res.data.msg;
                        }
                    }else {
                        errMsg = this.lang['res.code.un'];
                    }
                    this.$alert(errMsg,title,{dangerouslyUseHTMLString:true});
                }else{
                    this.$alert(this.lang['msg.succ'],'Successful',{type:'success'});
                }
            }).catch((error)=>{
                this.isDisabled = false;
                this.$alert(this.lang['errorMsg.axios'],'error');
                console.log(error);
            });
        },
        //清空表单数据
        clearForm(){
            this.form = {
                username:'',
                password:'',
                name:'',
                contact:'',
            }
        },
    },
}
</script>

<style scoped>
.main{
    width: 300px;
    margin:0 auto;
    margin-top: 20px;
}

</style>